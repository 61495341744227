<template>
  <UILoading v-if="loading" />
  <div v-else-if="statistieken" class="flex flex-col gap-8">
    <UISelectFixed v-model="orderBy" type="STATS_PERSONEEL_OP_BASIS_VAN" class="flex-shrink" />
    <div class="card">
      <h2 class="flex flex-row justify-between">
        <span>Personeel</span>
        <UIButtonCompact v-model="compact" class="small" />
      </h2>

      <div class="grid xl:grid-cols-4 gap-4">
        <BamTarget
          v-for="timeframe in Object.keys(statistieken)"
          :key="timeframe"
          :title="capitalize(timeframe)"
          :subtitle="getSubTitle(timeframe)"
        >
          <template #prefix>
            <ul v-for="item in statistieken[timeframe]" :key="timeframe + item.id" class="card mb-4 border-t-4 border-dashboard-dark">
              <li><strong>Chauffeur: </strong> {{ store.getters.chauffeur(item.id)?.label || item.id }}</li>
              <template v-if="!compact">
                <li v-for="option in STATS_PERSONEEL_OP_BASIS_VAN()" :key="option.value">
                  <strong>{{ option.label }}: </strong> {{ item[option.value] }}
                </li>
              </template>
            </ul>
          </template>
        </BamTarget>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import BamTarget from '@/components/Charts/BamTarget.vue'
import UIButtonCompact from '@/components/UI/Button/Compact.vue'
import UILoading from '@/components/UI/Loading.vue'
import UISelectFixed from '@/components/UI/Select/Fixed'

import { STATS_PERSONEEL_OP_BASIS_VAN } from '@/constants/OPTIONS'
import { capitalize } from '@/functions/formatText'
import useGetApi from '@/hooks/useGetApi.js'
import useRenderKey from '@/hooks/useRenderKey.js'

const props = defineProps({
  filters: Object,
  renderKey: [String, Number],
})

const store = useStore()

const compact = ref(false)
useRenderKey(props.renderKey)

const orderBy = ref(STATS_PERSONEEL_OP_BASIS_VAN()[0].value)
const apiData = computed(() => Object.assign({}, props.filters, { orderBy: orderBy.value }))
const { data, loading } = useGetApi('STATS_PERSONEEL', apiData, { watch: true })
const statistieken = computed(() => data.value?.statistieken || {})

const chauffeurTypes = ['onderaannemer', 'chauffeur', 'admin', 'master']
const countChauffeurs = timeframe => statistieken.value[timeframe]?.filter(el => chauffeurTypes.includes(el.type))?.length || 0
const countOnderaannamers = timeframe => statistieken.value[timeframe]?.filter(el => el.type === 'onderaannemer')?.length || 0

const getSubTitle = timeframe => {
  const countedChauffeurs = countChauffeurs(timeframe)
  const countedOnderaannemers = countOnderaannamers(timeframe)
  return `${countedChauffeurs} Chauffeur${countedChauffeurs === 1 ? '' : 's'} - ${countedOnderaannemers} Onderaannamer${countedOnderaannemers === 1 ? '' : 's'}`
}
</script>
